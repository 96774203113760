

$feature-flags: (
	grid-columns-16: true,
);

@import 'carbon-components/scss/globals/scss/styles.scss';

html, body {
	margin: 0;
	padding: 0;
}

body {
	width: 100%;
	background-color: #f4f4f4;
}


code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}


