.budget-chart-container {
	padding: 1rem;
	margin-top: 1rem;
}

.budget-chart-title {
	margin: 1rem;
}

.budget-chart-headers-container {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: space-between;
}
