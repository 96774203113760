@mixin landing-page-background() {
	background-color: $ui-01;
	position: relative;

	&::before {
		content: '';
		background: $ui-01;
		z-index: -1;
	}
}
