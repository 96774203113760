@import 'carbon-components/scss/globals/scss/spacing.scss';

@import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';

@import './mixins.scss';
@import './overrides.scss';

.budget-page__banner {
	padding-top: $spacing-07;
	padding-bottom: $spacing-07;
	@include landing-page-background;
}

.budget-page__back-button {
	margin-left: 2rem;
	margin-top: 2rem;
}

.budget-page__heading {
	@include carbon--type-style('productive-heading-05');
}

.budget-page__header-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.create-budget-wizard-container {
	display: flex;
	flex-direction: column;
	padding: $spacing-05;
}

.create-budget-wizard-templates-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: $spacing-05;
	flex-wrap: wrap;
}

.budget-page__budget-select-tile {
	margin: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;
	height: 15rem;
	width: 25rem;

	.bx--form-item {
		margin: 0;
		width: auto;
		height: auto;
	}

	* {
		margin: 0.5rem;
		text-align: center;
	}
}

.budget-page__budget-list {
	@media (min-width: 640px) {
		.bx--accordion__content {
			padding-right: 10%;
		}
	}
}

.budget-page__options {
	display: flex;
	flex-direction: row;
	gap: 2rem;
}

.budget-page__heading-edit {
	max-width: 16rem;
}

.budget-page__heading-edit-container {
	display: flex;
	flex-direction: row;
}
