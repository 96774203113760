.transaction-list__transaction-list-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.transaction-list__transaction-list-headers {
	display: flex;
	flex-direction: row;
	p {
		width: 33%;
	}
}

.transaction-list__transaction-list {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.transaction-list__list-item {
	display: block;
	width: 33%;
}



