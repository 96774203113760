@import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.App {
	height: 100%;
}

#root {
	height: 100%;
	min-height: 100vh;
}

.App-link {
	color: #61dafb;
}

main {
	display: block;
}

.bx--content {
	padding: 0;
	height: 100%;
}

.root-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: calc(100vh - 3rem);
}

.copyright {
	text-align: center;
	padding: 1rem;
}


// For debugging
// * {
// 	outline: 1px solid #f00 !important;
// }
