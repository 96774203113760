.mobile--budget-item-table-container {
	width: 100%;
}

.mobile--budget-table-header {
	display: flex;

	flex-direction: row;
	align-items: center;

	div {
		margin-left: 1rem;
	}

	p {
		text-align: left;
	}
}

.mobile--budget-accordion-item-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.mobile--budget-headers {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
}

.mobile--budget-table-container {

	padding: 1rem;

	.bx--accordion__content {
		padding: 0px;
	}
}

.mobile--budget-header-container {
	display: flex;
	flex-direction: row;
	align-items: center;

	> div {
		margin-left: 1rem;
	}
}

.mobile--budget-category-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;

	li {
		flex-grow: 1;
	}
}

.mobile--budget-category-accordion-header {
	padding-right: 1rem;
	display: flex;
	flex-direction: row;

	> {
		margin: 1rem;
	}
}

.mobile--budget-category-header-actions-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.mobile--budget-category-header-item {
	max-height: 3rem;
}

.mobile--budget-totals-container {
	margin-top: 1rem;
	width: 100;
	display: flex;
	flex-direction: column;

	p {
		padding-right: 1rem;
		width: 33%;
		padding-bottom: 1rem;
		padding-right: 0px;
		width: 100%;
	}
}

.mobile--budget-table-actions-row {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}


