
@import 'carbon-components/scss/globals/scss/spacing.scss';

@import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';

@import './mixins.scss';
@import './overrides.scss';

.landing-page__illo {
	max-width: 100%;
}

.landing-page__banner {
	padding-top: $spacing-05;
	padding-bottom: $spacing-07 * 4;
	@include landing-page-background;
}

.landing-page__heading {
	@include carbon--type-style('productive-heading-05');
}

.landing-page__r2 {
	margin-top: rem(-40px);

	.bx--tab-content {
		width: 100%;
	}
}

.bx--grid--no-gutter {
	background-color: white;
}



.landing-page__tab-content {
	padding-top: $layout-05;
	padding-bottom: $layout-05;
}

.landing-page__subheading {
	@include carbon--type-style('productive-heading-03');
	@include carbon--font-weight('semibold');
}

.landing-page__p {
	@include carbon--type-style('productive-heading-03');
	margin-top: $spacing-06;
	margin-bottom: $spacing-08;

	@include carbon--breakpoint-between((320px + 1), md) {
		max-width: 75%;
	}
}

.landing-page__r3 {
	padding-top: $spacing-09;
	padding-bottom: $spacing-09;
	@include landing-page-background;
}

.landing-page__label {
	@include carbon--type-style('heading-01');
}
