.budget-item-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: flex-start;
}

.budget-item-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}

.budget-item-cell {
	padding: 0.5rem;
}

.budget-item-btn {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.budget-item-mobile-header {
	margin-right: 1rem;
}
