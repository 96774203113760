.mobile--budget-item-row {
	display: flex;
	justify-content: space-between;
	align-content: flex-start;
	align-items: center;
	flex-wrap: wrap;

	flex-direction: column;
	align-items: flex-start;

}

.mobile--budget-item-cell {
	padding: 0.5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.mobile--budget-item-btn {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.mobile--budget-item-input-container {
	max-width: 65%;
	min-width: 50%;
}

.bx--number input[type="number"] {
	padding-right: 0px
}


.mobile--budget-item-mobile-header {
	margin-right: 1rem;
}
