.importTransactions__modal {
	.bx--modal-content {
		padding-right: 0px;
	}
}

.importTransactions__modal-contents {
	width: 100%;
	display: flex;
	flex-direction: column;
}

