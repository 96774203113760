@import 'carbon-components/scss/globals/scss/spacing.scss';

@import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';

@import './mixins.scss';
@import './overrides.scss';

.transaction-page__banner {
	padding-top: $spacing-05;
	padding-bottom: $spacing-07;
	@include landing-page-background;
}

.transaction-page__heading {
	@include carbon--type-style('productive-heading-05');
}

.transaction-list__load-container {
	display: flex;
	justify-content: center;
}

.transaction-page__transaction-list-container {
	.bx--accordion__content {
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.transaction-page__transaction-accordion-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
}

.transaction-page__transaction-accordion-item {
	width: 100%;
}

.transaction-page__transaction-accordion-item-btns {
	display: flex;
	flex-direction: row;
}

.transaction-page__frequency-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-height: 1em;
}

.transaction-page__monthly-container {
	display: flex;
	flex-direction: row;
}
